import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react'
import cx from 'classnames'
import camelCase from 'lodash/camelCase'
import { useAuth } from '@/auth'
import { Icon as LegacyIcon } from '@wiz/components'
import { OperateContext } from '@/pages/context/operate'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { useRouter, useLocationQuery, useLocation } from '@/router'
import Link from '@/components/Link'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import classes from '../index.module.css'

const OperateSideNav = () => {
  const [ isExpanded, setExpanded ] = useState(true)

  const {
    dashboardsComponentsDeepList, contextTwinId, labels, isEditing, handleEdit,
  } = useContext(OperateContext)
  const { selectedComponent, setSelectedComponent } = useGlobalExecute()

  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()
  const loc = useLocation()
  const { currentUser } = useAuth()
  const [ ,,, currentPage ] = loc.pathname.split('/')

  const filteredOptions = useMemo(() => dashboardsComponentsDeepList?.data?.filter((item) => {
    if (query.view === 'personal') {
      return item.ownerId === currentUser.id
    } if (query.view === 'org') {
      return !item.ownerId
    }
    return item.ownerId === currentUser.id || !item.ownerId
  }), [ dashboardsComponentsDeepList?.data, query.view ])

  const isOwn = useMemo(() => {
    if (filteredOptions?.length) {
      const twin = filteredOptions?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ filteredOptions, contextTwinId ])

  const categories = useMemo(
    () => filteredOptions?.reduce((acc, val) => {
      if (!contextTwinId || !isOwn) {
        return []
      }
      const label = labels?.find(l => l.id === val.labelId)

      const subCat = acc.find(item => item.labelId === val.labelId)
      const isChild = val?.twinId === contextTwinId

      if (!subCat && isChild) {
        acc.push({
          ...val,
          label: label?.name,
          value: val.id,
          icon: label?.icon?.includes('--') ? label?.icon : camelCase(label?.icon),
          order: label?.order,
          color: label?.color,
        })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ filteredOptions, labels, contextTwinId, isOwn ],
  )

  useEffect(() => {
    if (categories) {
      const categoryIds = categories.map(cat => cat.labelId)
      if (query.sub && query.category && selectedComponent?.category) {
        router.push({
          query: {
            ...query,
            category: !categoryIds.includes(selectedComponent?.category) ?
              categoryIds[0] :
              selectedComponent?.category,
            tree: '',
            contextTwinId: '',
          },
        })
      }
    }
  }, [ selectedComponent?.category, categories ])

  const getFirstDashboard = useCallback(
    labelId => filteredOptions
      .sort((a, b) => a.order - b.order)
      .find(g => g.labelId === labelId),
    [ filteredOptions ],
  )

  const handleRightBarClick = useCallback(async (labelId) => {
    if (isEditing && labelId !== query.category) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      handleEdit(false)
    }
    const firstDashboard = getFirstDashboard(labelId)
    setSelectedComponent({ ...selectedComponent, category: firstDashboard.labelId })
    router.push({
      name: 'controlpanel',
      query: {
        ...query, contextTwinId, category: labelId, sub: firstDashboard?.id,
      },
    })
  }, [
    query,
    router,
    contextTwinId,
    isEditing,
    handleEdit,
    selectedComponent,
    setSelectedComponent,
    getFirstDashboard,
  ])

  return (
    <>
      <Link
        name="controlpanel"
        query={{
          category: 'search',
        }}
        className={cx(
          'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
          classes.menuItem,
          { active: (!query.category || query.category === 'search' || !contextTwinId) && !currentPage },
        )}
        title={intl.t('form.actions.overview')}
      >
        <Icon name="faLocationDot" size="lg" className="me-2" />
        <span className="mt-1 fs-6">
          {intl.t('form.actions.overview')}
        </span>
      </Link>

      {categories?.length ? (
        <div>
          <div
            className={cx(
              'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
              classes.menuItem,
              { active: (query.category && query.category !== 'search') && !currentPage },
            )}
            onClick={() => setExpanded(!isExpanded)}
            aria-hidden
          >
            <Icon name="faObjectsColumn" className="me-2" size="lg" />
            <span className="fill-flex text-start text-truncate">{intl.t('menu.title.dashboards')}</span>
            {categories?.length ? (
              <Icon name={isExpanded ? 'faAngleUp' : 'faAngleDown'} className="ms-auto" />
            ) : null}

          </div>
          {categories?.length ? (
            <div className={cx(
              'd-flex flex-column',
              classes.wrapper,
              { [classes.child]: (query.category && query.category !== 'search') && !currentPage },
            )}
            >
              {isExpanded && categories?.map(({
                id, label, labelId, icon, color,
              }) => {
                let isLegacyIcon = true
                if (icon?.includes('--')) {
                  isLegacyIcon = false
                }
                return (
                  <Link
                    key={id}
                    name="controlpanel"
                    query={{
                      ...query, contextTwinId, category: labelId, sub: getFirstDashboard(labelId)?.id,
                    }}
                    className={cx(
                      'btn btn-secondary-alt py-2 ps-3 justify-content-start',
                      classes.menuItem,
                      { active: labelId === query.category },
                    )}
                    onClick={() => handleRightBarClick(labelId)}
                    title={intl.t('menu.tooltip.docs')}
                  >
                    {isLegacyIcon ? <Icon name={icon} color={color} size="sm" className="me-2" /> : (
                      <LegacyIcon
                        name={icon || 'placeholder'}
                        color={color}
                        width={11}
                        height={11}
                        className="me-2"
                      />
                    )}
                    <span
                      className="mt-1 fs-6 text-truncate"
                      style={{
                        textAlign: 'left',
                        wordBreak: 'break-word',
                      }}
                    >
                      {intl.t(label)}
                    </span>
                  </Link>
                )
              })}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={cx(
          'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
          classes.menuItem,
          { active: currentPage === 'de' },
        )}
        onClick={() => router.push({ name: 'de', params: { id: 'reset' } })}
        aria-hidden
      >
        <LegacyIcon name="wiz--menu--data-explorer" size="lg" width={11} className="me-2" />
        <span className="mt-1 fs-6">
          {intl.t('menu.title.explorer')}
        </span>
      </div>
    </>
  )
}

export default OperateSideNav
