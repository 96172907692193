import { useMemo, useCallback, useContext } from 'react'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import { useLocation, useLocationQuery } from '@/router'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { dbProvider, Q } from '@wiz/store'
import {
  PagesMap, CorePages as PAGES, AiLabPages, DataHubPages,
} from '@/utils/consts'
import useAppContext from '@/hooks/useAppContext'
import { useAuth } from '@/auth'
import PipelineActions from './pipelines'
import TemplateActions from './template'
// import ExperimentActions from './experiment'
import DigitalTwinActions from './digitalTwin'

const Actions = ({
  onAdd, onEdit,
}) => {
  const queryClient = useQueryClient()

  const {
    selectedIds,
    filters: contextFilters,
  } = useAppContext()

  const auth = useAuth()
  const intl = useIntl()
  const loc = useLocation()
  const { pipelineId, tree } = useLocationQuery()
  const [ ,, corePage, currentPage, id ] = loc.pathname.split('/')

  const {
    mutate: deleteComponent,
  } = useMutation({
    mutationKey: [ 'deleteComponent', id ],
    mutationFn: componentId => wizataApi.components.deleteById(componentId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'componentsList' ] })
    },
  })
  const {
    mutate: deleteTemplate,
  } = useMutation({
    mutationKey: [ 'deleteTemplate' ],
    mutationFn: templateId => wizataApi.templates.deleteById(templateId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.deleteError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'templatesPagedList' ] })
    },
  })
  const {
    mutate: deleteTrigger,
  } = useMutation({
    mutationKey: [ 'deleteTrigger', id ],
    mutationFn: triggerId => wizataApi.triggers.deleteById(triggerId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/triggers.deleteError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'triggersPagedList' ] })
    },
  })
  const {
    mutate: deleteExperiment,
  } = useMutation({
    mutationKey: [ 'deleteExperiment' ],
    mutationFn: experimentId => wizataApi.experiments.deleteById(experimentId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'experimentsList' ] })
    },
  })
  const {
    mutate: deletePipeline,
  } = useMutation({
    mutationKey: [ 'deletePipeline' ],
    mutationFn: pipelineId => wizataApi.pipelines.deleteById(pipelineId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'pipelinesList' ] })
    },
  })
  const {
    mutate: deleteExecution,
  } = useMutation({
    mutationKey: [ 'deleteExecution' ],
    mutationFn: executionId => wizataApi.executions.deleteById(executionId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'executionsList', contextFilters.dateFrom + contextFilters.dateTo ] })
    },
  })
  const {
    mutate: deleteSensor,
  } = useMutation({
    mutationKey: [ 'deleteSensor' ],
    mutationFn: sensorId => wizataApi.sensors.deleteById(sensorId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/sensors.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'sensorsPagedList' ] })
    },
  })
  const {
    mutate: deleteRegistration,
  } = useMutation({
    mutationKey: [ 'deleteRegistration', id ],
    mutationFn: regId => wizataApi.registrations.deleteById(regId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ 'templateRegistrations', id ])
    },
  })
  const {
    mutate: deleteCategory,
  } = useMutation({
    mutationKey: [ 'deleteCategory' ],
    mutationFn: catId => wizataApi.categories.deleteById(catId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'categoriesList' ] })
    },
  })
  const {
    mutate: deleteLabel,
  } = useMutation({
    mutationKey: [ 'deleteLabel' ],
    mutationFn: labelId => wizataApi.labels.deleteById(labelId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'labelsList' ] })
    },
  })
  const {
    mutate: deleteUnit,
  } = useMutation({
    mutationKey: [ 'deleteUnit' ],
    mutationFn: unitId => wizataApi.units.deleteById(unitId),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/templates.createError',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'unitsList' ] })
    },
  })
  // const {
  //   mutate: deleteTwin,
  // } = useMutation({
  //   mutationKey: [ 'deleteTwin' ],
  //   mutationFn: twinId => wizataApi.twins.deleteById(twinId),
  //   onError: (err) => {
  //     events.emit('app:notify', {
  //       type: 'error',
  //       title: 't/templates.createError',
  //       message: err.message,
  //       duration: 5000,
  //     })
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: [ 'twinsPagedList' ] })
  //   },
  // })

  const deleteTwin = useCallback(async (twinId) => {
    // await window.wizConfirm({ message: 't/units.confirmDelete' })
    const ids = [ twinId ] // TODO: in future use normal array for bulk delete
    const context = dbProvider.createBatchContext()

    const twinIds = ids.filter(id => id.indexOf('/') === -1)

    const items = await dbProvider.database.collections.get('twins')
      .query(Q.where('id', Q.oneOf(twinIds)))
      .fetch()

    for (const item of items) {
      await item.prepareRemove(context, {
        removeIds: twinIds,
      })
    }
    try {
      await dbProvider.batch(context)
    } catch (error) {
      events.emit('app:notify', {
        type: 'error',
        message: error.message,
      })
      throw error
    }
  }, [
  ])

  const deleteStreamJob = useCallback(async (sjId) => {
    const context = dbProvider.createBatchContext()
    const streamJob = await dbProvider.database.collections.get('stream_jobs')
      .query(Q.where('id', sjId))
      .fetch()

    await streamJob?.[0].prepareRemove(context)
    await dbProvider.batch(context)
  }, [])

  const deleteBusinessLabel = useCallback(async (blId) => {
    const context = dbProvider.createBatchContext()
    const businessLabel = await dbProvider.database.collections.get('business_labels')
      .query(Q.where('id', blId))
      .fetch()

    await businessLabel?.[0].prepareRemove(context)
    await dbProvider.batch(context)
  }, [])

  const handleRemove = useCallback(async () => {
    await window.wizConfirm({ message: 't/units.confirmDelete' })
    const _id = id || selectedIds[0]
    if (currentPage === PagesMap.Components) {
      await deleteComponent(_id)
    } else if (currentPage === PagesMap.Templates && id) {
      await deleteRegistration(selectedIds[0])
    } else if (currentPage === PagesMap.Templates) {
      await deleteTemplate(_id)
    } else if (currentPage === PagesMap.Triggers) {
      await deleteTrigger(_id)
    } else if (currentPage === PagesMap.Experiments) {
      await deleteExperiment(_id)
    } else if (currentPage === PagesMap.Executions) {
      await deleteExecution(_id)
    } else if (currentPage === PagesMap.Sensors) {
      await deleteSensor(_id)
    } else if (currentPage === PagesMap.Pipelines) {
      await deletePipeline(_id)
    } else if (currentPage === PagesMap.Categories) {
      await deleteCategory(_id)
    } else if (currentPage === PagesMap.Labels) {
      await deleteLabel(_id)
    } else if (currentPage === PagesMap.Units) {
      await deleteUnit(_id)
    } else if (currentPage === PagesMap.Twins) {
      await deleteTwin(_id)
    } else if (currentPage === PagesMap.StreamJobs) {
      await deleteStreamJob(_id)
    } else if (currentPage === PagesMap.BusinessLabels) {
      await deleteBusinessLabel(_id)
    }
  }, [
    id,
    selectedIds,
    currentPage,
    deleteComponent,
    deleteTemplate,
    deleteTrigger,
    deleteExperiment,
    deleteExecution,
    deleteSensor,
    deletePipeline,
    deleteRegistration,
    deleteCategory,
    deleteLabel,
    deleteUnit,
    deleteTwin,
    deleteStreamJob,
    deleteBusinessLabel,
  ])

  const pageActions = useMemo(() => {
    if (currentPage === 'pipelines-list') {
      return <PipelineActions onAction={() => onAdd('pipelines-list', true, pipelineId)} id={id} />
    } if (currentPage === 'templates' && id) {
      return <TemplateActions onAction={onAdd} id={id} />
    } if (corePage === 'datahub' && !!tree) {
      return <DigitalTwinActions tree={tree} />
    }
    return undefined
  }, [ corePage, currentPage, pipelineId, onAdd, id, tree ])

  const isAiLabPage = corePage === PAGES.DESIGN
  const isDataHubPage = corePage === PAGES.TWIN && !tree

  const pageWithActions = useMemo(() => {
    const page = AiLabPages[currentPage] || DataHubPages[currentPage]
    if (page) {
      return auth.checkAccessManage(page.permission) ? page : {}
    }
    return {}
  }, [ currentPage, auth ])

  return (
    <div className="d-flex align-items-center">
      {(isAiLabPage || isDataHubPage) ? (
        <>
          {pageWithActions.hasAdd ? (
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text me-2"
              onClick={() => onAdd()}
            >
              <Icon name="faAdd" className="me-2" />
              {intl.t('form.actions.add')}
            </button>
          ) : null}
          {pageWithActions.hasEdit ? (
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text me-2"
              onClick={() => onEdit()}
              disabled
            >
              <Icon name="faPenToSquare" className="me-2" />
              {intl.t('form.actions.edit')}
            </button>
          ) : null}
          {pageWithActions.hasDelete ? (
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text me-2"
              onClick={handleRemove}
              disabled={selectedIds?.length !== 1}
            >
              <Icon name="faTrash" className="me-2" />
              {intl.t('form.actions.remove')}
            </button>
          ) : null}
        </>
      ) : null}
      {pageActions}
    </div>
  )
}

export default Actions
