import { type, intersection } from 'superstruct'
import { Sensor } from '@wiz/store'
import * as types from '@/utils/types'

export default type({
  businessType: intersection([
    types.Required(),
    Sensor.schema.schema.businessType,
  ]),
  inputMode: Sensor.schema.schema.inputMode,
  hardwareId: intersection([
    types.Required(),
    types.Maxlen(128),
    types.Minlen(3),
    Sensor.schema.schema.hardwareId,
  ]),
  name: intersection([
    types.Required(),
    types.Maxlen(200),
    Sensor.schema.schema.name,
  ]),
  categoryId: Sensor.schema.schema.categoryId,
  unitId: Sensor.schema.schema.unitId,
  validity: Sensor.schema.schema.validity,
  description: intersection([
    types.Maxlen(450),
    Sensor.schema.schema.description,
  ]),
  batch: Sensor.schema.schema.batch,
  batchDataSource: Sensor.schema.schema.batchDataSource,
  latitude: Sensor.schema.schema.latitude,
  longitude: Sensor.schema.schema.longitude,
  icon: Sensor.schema.schema.icon,
  color: Sensor.schema.schema.color,
  twinId: Sensor.schema.schema.twinId,
  extraProperties: intersection([
    // types.Required(),
    types.Maxlen(8000),
  ]),
})
