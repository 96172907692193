import {
  memo,
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import classnames from 'classnames'
import { Duration } from 'luxon'
import {
  useDidUpdate,
  DropdownMenu,
  Icon,
  FormActionMenu,
  FormSelectNative,
  FormSelectDuration,
  FormSelectDateTimeRange,
  withObservables,
} from '@wiz/components'
import {
  consts,
  duration,
  getDateFrom,
  getDateTo,
  pick,
  uniqKey,
  compact,
  validateNumberChartValues,
  validateRawSamplingDuration,
} from '@wiz/utils'
import { dbProvider } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import { auth } from '@/auth'
import DataExplorer from '@/context/DataExplorer'
import DataExplorerEnv from '@/context/DataExplorerEnv'
import DataExplorerExecute from '@/context/DataExplorerExecute'
import BulkSensors from '@/components/Dialogs/BulkSensors'
import FormatDateTime from '@/containers/FormatDateTime'
import classes from './Filter.css'

const BulkSensorsMemo = memo(BulkSensors)

const ChartOperations = [
  {
    id: 'export',
    label: 't/form.actions.exportTitle',
    icon: 'fa--file-download',
    accessSubject: 'Job',
  },
  {
    id: 'notebook',
    label: 't/form.actions.createNotebook',
    icon: 'fa--file-contract',
    accessSubject: 'Job',
  },
  {
    id: 'dashboard',
    label: 't/dashboards.title',
    icon: 'fa--th-large',
    accessSubject: 'SectionDashboards',
  },
  {
    id: 'exploreValues',
    label: 't/form.actions.exploreValues',
    icon: 'fa--list-alt',
  },
  {
    id: 'downloadCSV',
    label: 't/form.actions.downloadCSV',
    icon: 'fa--file-csv',
  },
  {
    id: 'saveAsImage',
    label: 't/form.actions.saveAsImage',
    icon: 'fa--file-image',
  },
  {
    id: 'createStreamJob',
    label: 't/form.actions.createStreamJob',
    icon: 'mlworkflow',
    accessSubject: 'SectionStreamJobs',
    featureFlag: 'StreamJobs',
  },
]

const FormDataFields = [
  'duration',
  'offsetDuration',
  'dateTo',
  'dateFrom',
  'stepType',
  'stepCustom',
  'stepRequest',
]

const enhaceProps = withObservables([], () => ({
  featureFlags: dbProvider.observeFlags([ 'UseInflux2x', 'StreamJobs' ]),
}))

const Filter = ({
  className,
  onSaveAsImage,
  onExploreValues,
  onDownloadCSV,
  hasToggleFullscreen = true,
  hasSelectExplorers = true,
  hasSaveExplorer = true,
  featureFlags,
}) => {
  const refTargetDateAutoapply = useRef()
  const refDropdownDateAutoapply = useRef()
  const intl = useIntl()
  const context = useContext(DataExplorer)
  const runContext = useContext(DataExplorerExecute)
  const { BackComponent } = useContext(DataExplorerEnv)
  const [ durationInput, setDurationInput ] = useState(Boolean(context.data.dataFilter.duration))
  const [ formData, setFormData ] = useState(pick(context.data.dataFilter, FormDataFields))
  const [ bulkSensorsType, setBulkSensorsType ] = useState(null)
  const [ rawSamplingError, setRawSamplingError ] = useState(false)
  const [ seriesQueryMaxError, setSeriesQueryMaxError ] = useState(false)

  const sensorIds = useMemo(() => (
    compact(uniqKey(context.data.dataSources, 'sensorId'))
  ), [ context.data.dataSources ])

  const bulkParams = useMemo(() => {
    let data

    if (bulkSensorsType === 'dashboard') {
      data = {
        widgets: [
          {
            id: 'explorerChart',
            label: 'Explorer Chart',
            icon: 'fa--chart-area',
            type: 'chart',
            prepareSaveContextTo: (batchContext, model) => (
              context.prepareSaveContextTo(batchContext, model)
            ),
          },
        ],
      }
    } else if (
      bulkSensorsType === 'export' ||
      bulkSensorsType === 'notebook'
    ) {
      data = {
        parameters: {
          From: getDateFrom(context.data.dataFilter, true),
          To: getDateTo(context.data.dataFilter, true),
        },
      }

      const { stepType, stepCustom } = formData

      if (stepType === 'raw') {
        data.parameters.DataType = 'raw'
      } else {
        data.parameters.DataType = 'aggregated'
        data.parameters.IsBatch = stepType === 'batch'
        let interval

        if (stepType === 'auto') {
          interval = runContext.autoStepApplied
        } else if (stepType === 'dense') {
          interval = runContext.denseStepApplied
        } else {
          interval = stepCustom
        }

        if (interval) {
          data.parameters.Interval = Duration.fromMillis(interval).toISO()
        }
      }
    }

    return data
  }, [
    bulkSensorsType,
    formData.stepType,
    formData.stepCustom,
    runContext.autoStepApplied,
    runContext.denseStepApplied,
    context.prepareSaveContextTo,
    context.data.dataFilter.dateFrom,
    context.data.dataFilter.dateTo,
    context.data.dataFilter.duration,
  ])

  const handleSaveFormData = useCallback((data) => {
    const next = { ...formData, ...data }
    setFormData(next)
    if (context.settings.explorerDateAutoapply) {
      context.updateContext({ dataFilter: next })
    }
  }, [
    context,
    formData,
  ])

  const handleSaveFilters = useCallback(() => {
    context.updateContext({ dataFilter: formData })
  }, [ context, formData ])

  const handleCloseBulkSensors = useCallback(() => {
    setBulkSensorsType(null)
  }, [])

  const handleToggleDateAutoapply = useCallback(async () => {
    refDropdownDateAutoapply.current.close()
    const next = await context.toggleDateAutoapply()
    if (next) {
      context.updateContext({ dataFilter: formData })
    }
  }, [ context, formData ])

  const handleValidateNumberChartValues = useCallback((params) => {
    const data = {
      duration: formData.duration,
      dateTo: formData.dateTo,
      dateFrom: formData.dateFrom,
      stepType: formData.stepType,
      stepCustom: formData.stepCustom,
      ...params,
    }

    if (validateNumberChartValues(data, context.globalSettings.SeriesQueryMaxCount)) {
      setSeriesQueryMaxError(false)
    } else {
      setSeriesQueryMaxError(true)
    }
  }, [ context, formData ])

  const handleValidateRawSamplingDuration = useCallback((params) => {
    const data = {
      duration: formData.duration,
      dateTo: formData.dateTo,
      dateFrom: formData.dateFrom,
      stepType: formData.stepType,
      ...params,
    }

    if (validateRawSamplingDuration(data, context.globalSettings.RawDateRangeLimit)) {
      setRawSamplingError(false)
    } else {
      setRawSamplingError(true)
    }
  }, [ context, formData ])

  const handleAdjustTimePeriod = useCallback(() => {
    setSeriesQueryMaxError(false)
    const dateRangeLimit = formData.stepCustom * context.globalSettings.SeriesQueryMaxCount
    if (formData.duration) {
      handleSaveFormData({ duration: dateRangeLimit })
    } else if (formData.dateFrom && formData.dateTo) {
      handleSaveFormData({ dateFrom: formData.dateTo - dateRangeLimit })
    }
  }, [ formData, handleSaveFormData, context ])

  const handleAdjustStep = useCallback(() => {
    setSeriesQueryMaxError(false)
    const nextStepCustom = (
      Math.ceil(
        (formData.duration || formData.dateTo - formData.dateFrom) /
        context.globalSettings.SeriesQueryMaxCount / 1000,
      ) * 1000
    )
    handleSaveFormData({ stepCustom: nextStepCustom })
  }, [ formData, handleSaveFormData, context ])

  const handleAdjustTimePeriodRaw = useCallback(() => {
    setRawSamplingError(false)
    const dateRangeLimit = context.globalSettings.RawDateRangeLimit * 60 * 1000
    if (formData.duration) {
      handleSaveFormData({ duration: dateRangeLimit })
    } else if (formData.dateFrom && formData.dateTo) {
      handleSaveFormData({ dateFrom: formData.dateTo - dateRangeLimit })
    }
  }, [ formData, handleSaveFormData, context ])

  const handleSelectDense = useCallback(() => {
    setRawSamplingError(false)
    handleSaveFormData({ stepType: 'dense' })
  }, [ handleSaveFormData ])

  useDidUpdate(() => {
    const next = pick(context.data.dataFilter, FormDataFields)
    const nextDurationInput = Boolean(next.duration || (!next.dateFrom && !next.dateTo))
    setFormData(next)
    if (nextDurationInput !== durationInput) {
      setDurationInput(nextDurationInput)
    }
  }, [
    context.data.dataFilter,
  ])

  useEffect(() => {
    handleValidateRawSamplingDuration()
    handleValidateNumberChartValues()
  }, [
    handleValidateRawSamplingDuration,
    handleValidateNumberChartValues,
  ])

  const featureFlagOptions = useMemo(() => {
    const mappedOptions = [
      { id: consts.StepType.FirstN, name: `First ${context.globalSettings.RawQueryLimit} values` },
      { id: consts.StepType.LastN, name: `Last ${context.globalSettings.RawQueryLimit} values` },
    ]

    if (!featureFlags?.UseInflux2x) {
      mappedOptions.unshift({ id: consts.StepType.Raw, name: 'Sampling' })
    }

    return mappedOptions
  }, [ featureFlags?.UseInflux2x, context.globalSettings.RawQueryLimit ])

  return (
    <div className={classnames('d-flex align-items-start flex-wrap', className)}>
      <div className="m-1 flex-shrink-0">
        {BackComponent ? <BackComponent /> : null}

        {hasToggleFullscreen ? (
          <button
            type="button"
            name="fullscreen"
            className="btn btn-sm btn-text btn-flat-secondary me-1"
            onClick={context.toggleFullscreen}
          >
            <Icon name={context.settings.explorerViewFullscreen ? 'fa--compress' : 'fa--expand'} />
          </button>
        ) : null}

        <button
          type="button"
          className={classnames('btn btn-sm btn-text', {
            'btn-flat-secondary': !durationInput,
            'btn-flat-primary': durationInput,
          })}
          onClick={() => {
            if (durationInput) {
              handleSaveFormData({
                duration: null,
                stepRequest: null,
                dateFrom: getDateFrom(formData, true),
                dateTo: getDateTo(formData, true),
              })
              setDurationInput(false)
            } else {
              handleSaveFormData({
                dateTo: null,
                dateFrom: null,
                offsetDuration: formData.dateTo ? formData.dateTo - Date.now() : null,
                duration: formData.dateTo && formData.dateFrom ?
                  formData.dateTo - formData.dateFrom :
                  consts.DataExplorerDuration,
              })
              setDurationInput(true)
            }
          }}
        >
          <Icon name="fa--clock" />
        </button>
      </div>

      {durationInput ? (
        <div className="d-flex flex-shrink-0 m-1">
          <FormSelectDuration
            value={formData.duration}
            parts={formData.stepType === 'custom' ?
              'days,hours,minutes,seconds' :
              'years,months,weeks,days,hours,minutes'}
            className={classnames('flex-shrink-0 me-2', classes.duration)}
            size="sm"
            label={intl.t('form.fields.slidingWindow')}
            clearable
            onChange={(value) => {
              handleSaveFormData({
                duration: value,
                dateTo: null,
                dateFrom: null,
              })
            }}
          />

          <FormSelectDuration
            value={formData.offsetDuration}
            className={classnames('flex-shrink-0 me-2', classes.duration)}
            size="sm"
            label={intl.t('form.fields.offset')}
            clearable
            signed
            onChange={value => handleSaveFormData({ offsetDuration: value })}
          />
        </div>
      ) : (
        <div className="flex-shrink-0 m-1">
          <FormSelectDateTimeRange
            value={(
              formData.dateFrom && formData.dateTo ?
                [ formData.dateFrom, formData.dateTo ] :
                [ null, null ]
            )}
            DateTimeComponent={FormatDateTime}
            size="sm"
            disabled={!!formData.stepRequest}
            onChange={([ dateFrom, dateTo ]) => {
              handleSaveFormData({ dateFrom, dateTo })
            }}
          />
        </div>
      )}

      <div className="d-flex flex-column flex-shrink-0 m-1">
        <div className="d-flex flex-shrink-0">
          <FormSelectNative
            className="form-select-sm"
            value={formData.stepType}
            options={[
              {
                id: 'raw-options',
                name: 'Raw',
                options: featureFlagOptions,
              },
              {
                id: 'aggregated-options',
                name: 'Aggregated',
                options: [
                  {
                    id: consts.StepType.Auto,
                    name: runContext.autoStepApplied ?
                      `Smooth (${duration(runContext.autoStepApplied)})` : 'Smooth',
                  },
                  {
                    id: consts.StepType.Dense,
                    name: runContext.denseStepApplied ?
                      `Dense (${duration(runContext.denseStepApplied)})` : 'Dense',
                  },
                  { id: consts.StepType.Batch, name: 'Batch' },
                  {
                    id: consts.StepType.Custom,
                    name: formData.stepCustom ?
                      `Custom (${duration(formData.stepCustom)})` : 'Custom',
                  },
                ],
              },
            ]}
            onChange={(stepType) => {
              handleSaveFormData({ stepType })
            }}
          />

          {formData.stepType === 'custom' ? (
            <FormSelectDuration
              value={formData.stepCustom}
              className={classnames('flex-shrink-0 ms-2', classes.duration)}
              size="sm"
              label={intl.t('form.fields.stepDuration')}
              parts="days,hours,minutes,seconds"
              clearable
              onChange={stepCustom => handleSaveFormData({ stepCustom })}
            />
          ) : null}
        </div>

        {rawSamplingError ? (
          <div className="d-flex align-items-baseline mb-n2 small mt-1">
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary p-0 px-1 border-0 me-1"
              onClick={handleAdjustTimePeriodRaw}
            >
              {intl.t('form.actions.adjusTimePeriod')}
            </button>
            {intl.t('form.fields.or')}
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary p-0 px-1 border-0 ms-1"
              onClick={handleSelectDense}
            >
              {intl.t('form.actions.selectDense')}
            </button>
          </div>
        ) : null}

        {seriesQueryMaxError ? (
          <div className="d-flex align-items-baseline mb-n2 small mt-1">
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary p-0 px-1 border-0 me-1"
              onClick={handleAdjustTimePeriod}
            >
              {intl.t('form.actions.adjusTimePeriod')}
            </button>
            {intl.t('form.fields.or')}
            <button
              type="button"
              className="btn btn-sm btn-flat-secondary p-0 px-1 border-0 ms-1"
              onClick={handleAdjustStep}
            >
              {intl.t('form.actions.adjustStep')}
            </button>
          </div>
        ) : null}
      </div>

      {durationInput ? (
        <div className="flex-shrink-0 m-1">
          <FormSelectNative
            className="form-select-sm"
            placeholder="--"
            options={consts.StepRequests}
            value={formData.stepRequest || ''}
            onChange={value => handleSaveFormData({ stepRequest: Number(value) || null })}
          />
        </div>
      ) : null}

      <div className="d-flex align-items-center flex-shrink-0 m-1">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-sm btn-text btn-flat-primary"
            onClick={handleSaveFilters}
          >
            <Icon name="fa--play" />
            {context.settings.explorerDateAutoapply ? <span className="ms-1">auto</span> : null}
          </button>

          <button
            ref={refTargetDateAutoapply}
            type="button"
            className="btn btn-sm btn-text btn-flat-secondary px-1"
          >
            <Icon name="fa--chevron-down" />
          </button>

          <DropdownMenu
            ref={refDropdownDateAutoapply}
            target={refTargetDateAutoapply}
            options={context.settings.explorerDateAutoapply ?
              [{
                id: 'off',
                name: 'Turn off auto apply',
                icon: 'fa--toggle-on',
                iconSize: 'lg',
              }] :
              [{
                id: 'on',
                name: 'Turn on auto apply',
                icon: 'fa--toggle-off',
                iconSize: 'lg',
              }]}
            onClick={handleToggleDateAutoapply}
          />
        </div>
      </div>

      <div className="flex-shrink-0 m-1">
        <button
          type="button"
          className="btn btn-sm btn-text btn-flat-secondary me-1"
          onClick={() => runContext.setRightBar('view')}
        >
          <Icon name="fa--paint-brush" />
        </button>

        {hasSelectExplorers ? (
          <button
            type="button"
            className="btn btn-sm btn-text btn-flat-secondary me-1"
            onClick={() => runContext.setRightBar('explorers')}
          >
            <Icon name="fa--bookmark" />
          </button>
        ) : null}

        {hasSaveExplorer ? (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-text btn-flat-secondary"
              onClick={() => {
                if (context.explorer.id) {
                  context.saveContext()
                } else {
                  runContext.setRightBar('create')
                }
              }}
            >
              <Icon name="fa--save" />
              {context.settings.explorerAutosave ? <span className="ms-1">auto</span> : null}
            </button>
            <button
              type="button"
              className="btn btn-sm btn-text btn-flat-secondary px-1"
              onClick={() => runContext.setRightBar(context.explorer.id ? 'save' : 'create')}
            >
              <Icon name="fa--chevron-down" />
            </button>
          </div>
        ) : null}
      </div>

      <div className="flex-shrink-0 m-1">
        <FormActionMenu
          title={intl.t('form.fields.chartActions')}
          className="btn-sm btn-flat-secondary"
          options={ChartOperations
            .filter((item) => {
              if (item.id === 'createStreamJob') {
                return (item.featureFlag && (featureFlags[item.featureFlag] ?? false))
              }
              return true
            })
            .map(item => ({
              ...item,
              label: intl.t(item.label),
              disabled: item.accessSubject && !auth.checkAccessCreate(item.accessSubject),
            }))}
          onClick={({ id }) => {
            if (id === 'saveAsImage') {
              onSaveAsImage?.()
            } else if (id === 'exploreValues') {
              onExploreValues?.()
            } else if (id === 'downloadCSV') {
              onDownloadCSV?.()
            } else if (id === 'createStreamJob') {
              context.createStreamJob()
            } else {
              setBulkSensorsType(id)
            }
          }}
        />

        {bulkSensorsType ? (
          <BulkSensorsMemo
            type={bulkSensorsType}
            params={bulkParams}
            sensorIds={sensorIds}
            onClose={handleCloseBulkSensors}
          />
        ) : null}
      </div>
    </div>
  )
}

export default enhaceProps(Filter)
