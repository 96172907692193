import { forwardRef, useMemo, useState } from 'react'
import { useMobile, withObservables } from '@wiz/components'
import { useAuth } from '@/auth'
import { dbProvider } from '@wiz/store'
import RightBarCommands from '@/containers/RightBarCommands'
import RightBarCreateCommand from '@/components/RightBarCreateCommand'
import RightBarDashboard from '@/containers/RightBarDashboard'
import RightBarDiagram from '@/components/RightBarDiagram'
import RightBarEvents from '@/containers/RightBarEvents'
import RightBarExplorer from '@/containers/RightBarExplorer'
import RightBarInfo from '@/containers/RightBarInfo'
import RightBarInputSensorValue from '@/components/RightBarInputSensorValue'
import RightBarBoard from '@/components/RightBarLinked/Board'
import RightBarLinkedDashboard from '@/components/RightBarLinked/Dashboard'
import RightBarLinkedExplorer from '@/components/RightBarLinked/Explorer'
import RightBarJobs from '@/components/RightBarLinked/Jobs'
import RightBarStreamJob from '@/containers/RightBarStreamJob'
import RightBarSolutionsList from '@/containers/RightBarSolutionsList'
import RightBarWizard from '@/containers/RightBarWizard'
import RightBarResults from '@/containers/ExperimentResult'
import RightBarFindReplace from '@/containers/RightBarFindReplace'
import RightBarRegistrations from '@/components/RightBarRegistrations'
import RightBarDashboardsComponents from '@/components/RightBarDashboardsComponents'

export const RightBarItems = [
  {
    id: 'edit',
    icon: 'faEdit',
    title: 't/twin.rightMenu.edit',
    dialogTitle: 't/twin.rightMenutitle.edit',
    canDisable: true,
    access: auth => auth.checkAccessManage('Twin'),
  },
  {
    id: 'sensors',
    icon: 'faSignalStream',
    title: 't/twin.rightMenu.datapoints',
    dialogTitle: 't/twin.rightMenutitle.datapoints',
    showSelectedCount: true,
    canDisable: true,
    component: RightBarInfo,
    access: auth => auth.checkAccessManage('SectionEvents'),
  },
  {
    id: 'solutions',
    icon: 'faFlask',
    title: 't/experiments.titleRightBar',
    canDisable: true,
    component: RightBarSolutionsList,
  },

  {
    id: 'events',
    icon: 'faCalendar',
    title: 't/events.title',
    canDisable: true,
    component: RightBarEvents,
    access: auth => auth.checkAccessManage('SectionEvents'),
  },
  {
    id: 'linkedExplorer',
    icon: 'faChartBar',
    title: 't/twin.rightMenu.explorer',
    canDisable: true,
    component: RightBarLinkedExplorer,
  },
  {
    id: 'linkedJobs',
    icon: 'faCogs',
    title: 't/twin.rightMenu.jobs',
    canDisable: true,
    component: RightBarJobs,
    featureFlag: 'StreamJobs',
  },
  {
    id: 'linkedBoard',
    icon: 'faNetworkWired',
    title: 't/twin.rightMenu.board',
    canDisable: true,
    component: RightBarBoard,
  },
  {
    id: 'linkedDashboard',
    icon: 'faChalkboard',
    title: 't/twin.rightMenu.dashboard',
    canDisable: true,
    component: RightBarLinkedDashboard,
  },
  {
    id: 'commands',
    icon: 'faTerminal',
    title: 't/twin.rightMenu.commands',
    canDisable: true,
    component: RightBarCommands,
    access: auth => auth.checkAccessManage('SectionDeviceCommands'),
  },
  // {
  //   id: 'registrations',
  //   icon: 'fa--industry',
  //   title: 't/templates.leftmenu.registrations',
  //   canDisable: true,
  //   component: RightBarRegistrations,
  // },
  {
    id: 'dashboardsComponents',
    icon: 'faIndustry',
    title: 't/templates.leftmenu.components',
    canDisable: true,
    component: RightBarDashboardsComponents,
  },
  {
    id: 'dashboard',
    icon: 'faInfoCircle',
    hidden: true,
    component: RightBarDashboard,
    access: auth => auth.checkAccessManage('SectionDashboards'),
  },
  {
    id: 'explorer',
    icon: 'faInfoCircle',
    hidden: true,
    component: RightBarExplorer,
    access: auth => auth.checkAccessManage('SectionDataExplorer'),
  },
  {
    id: 'streamJob',
    icon: 'faInfoCircle',
    hidden: true,
    component: RightBarStreamJob,
    access: auth => auth.checkAccessManage('SectionStreamJobs'),
    featureFlag: 'StreamJobs',
  },
  {
    id: 'createCommand',
    icon: 'faTerminal',
    title: 't/edge.commands.titleCreate',
    hidden: true,
    component: RightBarCreateCommand,
    access: auth => auth.checkAccessManage('SectionDeviceCommands'),
  },
  {
    id: 'inputSensorValue',
    icon: 'faPenSquare',
    title: 't/form.actions.inputSensorValue',
    hidden: true,
    component: RightBarInputSensorValue,
    access: auth => auth.checkAccessManage('SectionDigitalTwinSensors'),
  },
  {
    id: 'runWizard',
    icon: 'faLightbulb',
    title: 't/experiments.titleWizard',
    hidden: true,
    component: RightBarWizard,
  },
  {
    id: 'experiment',
    icon: 'faLightbulb',
    hidden: true,
    component: RightBarResults,
  },
  {
    id: 'findReplace',
    icon: 'faSearch',
    title: 't/form.actions.findReplace',
    hidden: true,
    component: RightBarFindReplace,
  },
]

const RightBarTwinGraph = forwardRef((props, ref) => {
  const [ attachedSensorsCount, setAttachedSensorsCount ] = useState(0)

  const auth = useAuth()
  const isMobile = useMobile()

  const options = useMemo(() => (
    RightBarItems
      .filter((item) => {
        if (item.featureFlag && !(props.featureFlags[item.featureFlag] ?? false)) {
          return false
        }

        if (!item.access || item.access(auth)) {
          return true
        }

        return false
      })
      .map(item => ({
        ...item,
        hidden: isMobile || item.hidden,
      }))
  ), [ auth, isMobile, props.featureFlags ])

  return (
    <RightBarDiagram
      ref={ref}
      {...props}
      attachedSensorsCount={attachedSensorsCount}
      setAttachedSensorsCount={setAttachedSensorsCount}
      options={options}
    />
  )
})

const enhanceSettings = withObservables([], () => ({
  featureFlags: dbProvider.observeFlags([ 'StreamJobs' ]),
}))

export default enhanceSettings(RightBarTwinGraph)
